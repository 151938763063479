import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "mobx-react";
import PlusConfigProvider from '@fle-ui/plus-config-provider';

import * as stores from './store/index'

import RouterPack from './router';


import { parseQueryString } from '@/utils/common';

import './App.less';


const App = () => {

  useEffect(() => {
    const { token, from = '', loginLink = '' } = parseQueryString() as any;
    if (token) {
      window.localStorage.setItem('token', token);
    }
    if (from) {
      window.localStorage.setItem('from', from);
    }
    if (loginLink) {
      window.localStorage.setItem('loginLink', loginLink);
    }
  }, [])

  return (
    <Provider {...stores}>
      <PlusConfigProvider prefixCls="ant">
        <RouterPack/>
      </PlusConfigProvider>
    </Provider>
  );

}

export default App;
