import { makeAutoObservable } from 'mobx';
import httpClient from '@/utils/httpClient';
import Api from '@/config/api';
import { getApi } from '@/utils/mapApi';

const { common } = Api;

class CommonStore {
  constructor() {
    makeAutoObservable(this);
  }

  /**
   * 获取地址
   */
  getAddress = async (params: any) => {
    let res = await httpClient.post(common.get_address, params) as any;
    if (res && res.success) {
      return res.data;
    }
  };

  /**
   * 获取分类树状
   */

  categorThreeList = [];

  getCategorThreeList = async (params: any = { parentId: 0 }) => {

    try {
      const { data } = await httpClient.post(common.get_categor_tree_list, params) as any;
      this.categorThreeList = data || [];
      return data;
    } catch (e) {

    }
  };
  getCategorThreeList1 = async (params: any = { name:''}) => {

    try {
      const { data } = await httpClient.get(common.get_categor_tree_list1, {params}) as any;
      this.categorThreeList = data || [];
      return data;
    } catch (e) {

    }
  };
  /**
   * 获取供应商简称
   */

  getSupplierName = async (params: any = {}) => {
    return httpClient.get(common.get_supplier_name, { params }) as any;
  };


  /**
   * 校验专题是否有商品已经存在于站点中
   */

  checkSiteTopicGoods = async (params: any = {}) => {
    return httpClient.post(common.check_site_topic_goods, params) as any;
  };


  /**
  * 获取品牌列表
  */

  getBrand = async (params: any = {}) => {
    return httpClient.post(common.get_brand, params) as any;
  }

  /**
  * 获取微页面信息
  */

  getTinyInfo = async (params: any = {}) => {
    return getApi('get_tiny_info', params);
  }


  /**
  * 获取专题信息
  */

  getSpecialInfo = async (params: any = {}) => {
    return getApi('get_special_info', params);
  }

  /**
   * 获取专题信息
   */

  getGoodsInfo = async (params: any = {}) => {
    return httpClient.post(common.get_common_goods_info, params) as any;
  }

  /**
   * 获取品牌信息
   */

  getBrandInfo = async (params: any = {}) => {
    return httpClient.post(common.get_common_brand_info, params) as any;
  }

  /**
  * 获取分类信息
  */

  getCategoryInfo = async (params: any = {}) => {
    return httpClient.post(common.get_common_category_info1, params) as any;
  }
  // 获取品类
  getCategoryInfo1 = async (params: any = {}) => {
    return httpClient.post(common.get_common_category_info1, params) as any;
  }
  /**
   * 获取分类信息
   */

  getGoodsGroupList = async (params: any = {}) => {
    return httpClient.post(common.get_site_goods_group, params) as any;
  }


  /**
  * 获取分组信息
  */

  getSiteGoodsGroupInfo = async (params: any = {}) => {
    return httpClient.post(common.get_site_goods_group_info, params) as any;
  }

  /**
   * 获取方案信息
   */

  getProjectInfo = async (params: any = {}) => {
    return httpClient.post(common.get_programs_by_name, params) as any;
  }

  /**
   * 获取卡片信息
   */

  getNameCardInfo = async (params: any = {}) => {
    return httpClient.post(common.get_businessCard_list, params) as any;
  }


  //组织架构
  organizational = {}

  //获取组织架构
  async getOrganizational(params: any = {}) {
    const { success, data } = await httpClient.post(common.get_organizational, params) as any
    if (success) {
      this.organizational = data
    }
  }


  
  /**
   * 获取蛋糕叔叔城市
   */

   getDgssCity = async (params: any = {}) => {
    return httpClient.post(common.get_dgss_city, params) as any;
  }
  //获取猫超类目
  getMCCategory = async (params:any) => {
    return await httpClient.get(common.get_mc_category, {params}) as any;
};

}


export default new CommonStore()