
import { toJS } from 'mobx';
import { getyunyingGoodsDetails, getSiteGoodsDetails } from '@/config';
import axios from 'axios'
import { mainHost } from '@/config/index';
import api from '@/config/api';
import { message } from 'antd';

/**
 * 格式化url参数
 */
export const parseQueryString = () => {
  const url = window.location.href
  const regUrl = /^[^\?]+\?([\w\W]+)$/ //eslint-disable-line
  const regPara = /([^&=]+)=([\w\W]*?)(&|$|#)/g
  const arrUrl = regUrl.exec(url)
  let ret = {}
  if (arrUrl && arrUrl[1]) {
    const strPara = arrUrl[1]
    let result
    while ((result = regPara.exec(strPara)) != null) {
      ret[result[1]] = result[2]
    }
  }
  return ret
}

export const toDecimal2 = (x) => {
  var f = parseFloat(x);
  if (isNaN(f)) {
    return false;
  }
  var f = Math.round(x * 100) / 100;
  var s = f.toString();
  var rs = s.indexOf('.');
  if (rs < 0) {
    rs = s.length;
    s += '.';
  }
  while (s.length <= rs + 2) {
    s += '0';
  }
  return s;
}

// 分转元
export const formatPrice = (fen) => {
  let num = fen;
  num = fen * 0.01;
  num += '';
  let reg = num.indexOf('.') > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;
  num = num.replace(reg, '$1');
  num = toDecimal2(num)
  return num;
}

export const mobxToJavaScript = (originMobx) => {
  return JSON.parse(JSON.stringify(toJS(originMobx)));
}

export const getGoodsUrl = (id, editId = '') => {
  const { genre = 'site' } = parseQueryString();
  if (genre === 'site') {
    return getSiteGoodsDetails(id, editId);
  }
  return getyunyingGoodsDetails(id);
}

export const getLinkUrl = (linkType, link, editId) => {
  let url = '';
  switch (linkType) {
    case 'url':
      url = link;
      break;
    case 'goods':
      url = getGoodsUrl(link, editId);
      break;
    case 'special':
      url = `baid.com?id=${link}`;
      break;
    default:
  }
  return url
}


export const removePropertyOfNull = (obj) => {
  Object.keys(obj).forEach(item => {
    if (obj[item] !== 0 && !obj[item]) delete obj[item]
  })
  return obj;
}

export const scrollAnimation = (origin, currentY, targetY, speed = 10) => {
  // 计算需要移动的距离
  let needScrollTop = targetY - currentY;
  let _currentY = currentY;
  setTimeout(() => {
    // 一次调用滑动帧数，每次调用会不一样
    const dist = Math.ceil(needScrollTop / 10);
    _currentY += dist;
    origin.scrollTo(_currentY, currentY)
    // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
    if (needScrollTop > 10 || needScrollTop < -10) {
      scrollAnimation(origin, _currentY, targetY, speed);
    } else {
      origin.scrollTo(_currentY, targetY);
    }
  }, speed)
}

export const replaceParams = (url, arg, arg_val) => {
  const pattern = arg + '=([^&]*)';
  const replaceText = arg + '=' + arg_val;
  if (url.match(pattern)) {
    let tmp = '/(' + arg + '=)([^&]*)/gi';
    tmp = url.replace(eval(tmp), replaceText);
    return tmp;
  } else {
    if (url.match('[\?]')) {
      return url + '&' + replaceText;
    } else {
      return url + '?' + replaceText;
    }
  }
}


export const maxTotal = 10000; // 最大total限制

// 最大不得超过10000
const getBorderTotal = (total) => Math.min(maxTotal, total);

// 是否超出边界
const getIsOvertop = (pageSize) => (Math.ceil(getBorderTotal() / pageSize) * pageSize) > maxTotal;

// 取真实边界值
export const getFakeTotal = (pageSize, total) => {
  return getBorderTotal(total) - (getIsOvertop(pageSize) ? (getBorderTotal(total) % pageSize) : 0);
}

export const stringToRGBString = (color) => {
  let r, g, b;
  if (color.length === 4) {//4位颜色处理,简写模式
    r = parseInt(color.substring(1, 2) + color.substring(1, 2), 16);
    g = parseInt(color.substring(2, 3) + color.substring(2, 3), 16);
    b = parseInt(color.substring(3) + color.substring(3), 16)
  } else {//7位颜色字符串处理
    r = parseInt(color.substring(1, 3), 16);
    g = parseInt(color.substring(3, 5), 16);
    b = parseInt(color.substring(5), 16)
  }
  return `rgba(${r},${g},${b},1)`
}


// 将base64转换为blob
export const dataURLtoFile = (dataURI, type) => {
  let binary = atob(dataURI.split(',')[1]);
  let array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: type });
}

export function uploadBase64(baseStr, successFn) {
  return new Promise((resolve, reject) => {
    // 图片地址
    let blob = dataURLtoFile(baseStr, 'image/jpeg');
    var formData = new FormData();
    let fileOfBlob = new File([blob], new Date() + '.jpg'); // 重命名了
    formData.append("file", fileOfBlob);
    axios({
      url: mainHost + [api['common.upload']], //用于文件上传的服务器端请求地址
      dataType: 'json',
      method: 'post',
      data: formData,
      headers: {
        authority_token: window.localStorage.getItem('token') || '',
      }
    }).then((res) => {
      if (res?.data?.success) {
        const url = res?.data?.data?.url
        resolve(url)
      } else {
        message.error('上传视频封面出错')
        reject('上传视频封面出错')
      }
    }).catch(e => {
      reject(e)
    })
  })
}