import React from 'react';
import type { RoutesType } from '@fle-ui/plus-router';
import Layout from '../layout';

const routers: RoutesType = [
	{
		path: '/',
		element: <Layout />,
		children: [
			{
				path: '/fitment/:editId',
				component: () => import(/* webpackChunkName: "fitment" */ '../views/fitment')
			},
			{
				path: '/preview/:editId',
				component: () => import(/* webpackChunkName: "preview" */ '../views/preview')
			},
			{
				path: '/saas/preview/:editId',
				component: () => import(/* webpackChunkName: "saas-preview" */ '../views/saas/preview')
			},
			{
				path: '/goods/details/:goodsId',
				component: () => import(/* webpackChunkName: "fitment" */ '../views/goods/details')
			},
		]
	}
];

export default routers;