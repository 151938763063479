/*
 * @Author: your name
 * @Date: 2020-01-16 21:02:47
 * @LastEditTime: 2021-07-19 15:19:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /fx-operate-admin/src/config/api.js
 */
const api = {
  'common.upload': '/basic/new/file/upload',
  'common': {
    'get_address': "/basic/area/areas", // 获取地址
    'get_common_tag': '/fleshopy/goods-tag/getList', // 获取标签
    'get_select_goods_list': '/fleshopy/admin/template/goodsPageList', // 精选商品挑选分页列表
    'get_categor_tree_list1': '/fleshopy/goodsCategory/tree', // 获取分类树状
    'get_categor_tree_list': '/fleshopy/category/getTreeList', // 获取分类树状
    'get_select_special_list': '/fleshopy/miniprogram/site/site-topic/miniProgramPageList', // 站点/模板专题挑选分页
    'get_special_parent_info': '/admin/fleshopy/special-topic/getInfoById', // 获取父专题信息
    'get_select_goods_list_site': '/fleshopy/merchant/site/site-goods/pageList', // 站点商品列表
    'get_goods_list': '/fleshopy/admin/template/goodsPageList', // 获取商品
    'get_goods_list_site': '/fleshopy/merchant/site/site-goods/pageList', // 获取商品
    'get_supplier_name': '/fleshopy/supplier/short-names/page', // 分页搜索供应商简称
    'check_site_topic_goods': '/fleshopy/miniprogram/site/site-topic/siteContainTopicGoods', // 校验专题是否有商品已经存在于站点中
    'get_jd_goods_stock': '/fleshopy/stock/getStockBatch', // 获取京东库存
    'get_brand': '/fleshopy/brand/getListPage', // 获取品牌列表 - 分页

    'get_tem_tiny_info': '/fleshopy/admin/templateDecoration/list', // 批量获取微页面相关信息 模版
    'get_site_tiny_info': '/fleshopy/merchant/site/siteDecoration/list', // 批量获取微页面相关信息 站点

    'get_site_group_info': '/fleshopy/site/goods/group/getList',  // 根据id查询多个个分组

    'get_common_category_info': '/fleshopy/category/getListByIds', // 根据分类ID列表获取列表
    'get_common_category_info1': '/fleshopy/goodsCategory/getListByIds', // 根据分类ID列表获取列表

    'get_common_brand_info': '/fleshopy/brand/getListByIds', // 品牌列表

    'get_common_goods_info': '/fleshopy/goods/getListByIds', // 商品

    'get_tem_special_info': '/admin/fleshopy/special-topic/selectTopic', // 专题
    'get_site_special_info': '/fleshopy/specialtopic/special-topic/getInfoById', // 专题
    'get_site_goods_group': '/fleshopy/group/group/getGroupList', // 商品分组
    'get_site_goods_group_info': '/fleshopy/group/group/getGroupInfo',//商品分组信息详情

    'get_programs_by_name': '/program/goods/getProgramsByName', // 根据名称、类型模糊查询方案
    'get_businessCard_list': '/fleshopy/businessCard/list', // 根据部门id查询名片列表

    'get_organizational': '/elebuys/member/department/tree', // 获取组织架构

    'get_dgss_city': '/supplier/dgss/city/allList', // 获取蛋糕叔叔城市
    'get_mc_category': '/merchant/supplier/ali/listCategory',


  },
  'fitment': {
    'get_group_goods_list': '/fleshopy/group/group-goods/getGroupGoodsList',// 商品分组列表详情（商品列表分页）
    'get_fitment_tem_tiny_list': '/fleshopy/admin/templateDecoration/listTemplateDecorations', // 获取该模版下的微页面列表
    'get_fitment_content': '/fleshopy/admin/templateDecoration/getDecorationContent', // 获取装修内容
    'update_fitment_content': '/fleshopy/admin/templateDecoration/updateDecoration', // 模板装修更新
    'add_goods_to_template': '/fleshopy/admin/template/addGoods', // 挑选商品到模版
    'add_special_to_template': '/fleshopy/admin/template/addTopic', // 挑选专题到模版
    'add_goods_to_site': '/fleshopy/merchant/site/site-goods/addGoods', // 添加商品到站点
    'add_special_to_site': '/fleshopy/merchant/site/addTopic', // 站点添加专题
    'get_special_goods_list': '/admin/fleshopy/special-topic/topicGoodsPageList', // 专题商品分页列表
    'get_site_special_goods_list': '/merchant/fleshopy/special-topic/topicGoodsPageList', // 站点端专题详情

    'get_site_content': '/fleshopy/merchant/site/siteDecoration/getDecorationContent', // 站点信息获取
    'updata_site_content': '/fleshopy/merchant/site/siteDecoration/updateDecoration', // 装修保存、发布

    'get_template_info': '/fleshopy/admin/template/getDetail', // 获取模板信息
    'get_site_info': '/fleshopy/merchant/site/getSite', // 站点信息获取

    'get_share_template': '/fleshopy/admin/template/getShare', // 模板分享信息获取
    'update_share_config_template': '/fleshopy/admin/template/addUpdateTemplateShare', // 模版分享设置

    'get_share_site': '/fleshopy/merchant/site/getShare', // 站点分享信息获取
    'update_share_config_site': '/fleshopy/merchant/site/shareConfig', // 站点分享设置

    'get_fitment_site_tiny_list': '/fleshopy/merchant/site/siteDecoration/listSiteDecorations', // 获取该站点下微页面列表

    'get_pick_goods_to_site_status': '/fleshopy/merchant/site/site-goods/getPickGoodsToSiteStatus', // 挑选商品到站点状态查询

    'add_tempalte_brand_goods': '/fleshopy/admin/template/addBrandGoods', // 添加品牌到模版
    'get_tempalte_brand_goods': '/fleshopy/admin/template/brandIdList', // 获取品牌到模版

    'add_tempalte_category_goods': '/fleshopy/admin/template/addCategoryGoods', // 添加分类到模版
    'get_tempalte_category_goods': '/fleshopy/admin/template/categoryIdList', // 获取分类到模版

    'site_check_before_goods': '/fleshopy/merchant/site/site-goods/checkBeforeAddGoods', // 添加商品之前 - 亏本校验
    'site_check_before_special': '/fleshopy/merchant/site/checkBeforeAddGoods',

    'get_member_shop': '/merchant/member/shop/shops/search/v2', // 搜索商铺列表V2
    'get_select_pageList_for_mall': '/fleshopy/merchant/site/site-goods/selectPageListForMall', // 站点挑选外部商品

    'add_topic_check_loss': '/fleshopy/miniprogram/site/site-topic/addTopicCheckLoss', // 站点添加专题亏本校验
    'change_price_pick_goods': '/fleshopy/merchant/site/site-goods/addGoods', // 改价
    'get_special_goods_ids': '/fleshopy/specialtopic/special-topic/getGoodsPageList', // 专题商品
    'addBrandOrCategory': '/fleshopy/admin/template/addBrandOrCategory', // 运营端模板添加品牌或分类
    'get_goods_all_flag_list': "/fleshopy/goods/flag/allList" // 商品标签列表
  },
};

export default api;
