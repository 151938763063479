import axios from "axios";
import { notification } from "antd";
import { mainHost, DATA_CODE, getyunyingBase, getPelitenLoginHost } from "../config";
import { parseQueryString } from '@/utils/common';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const service = axios.create({
  baseURL: mainHost,
  timeout: 10000,
});

service.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json;charset=UTF-8";
    config.headers["authority_token"] = localStorage.getItem("token");
    config.url = `${config.url}?__date=${new Date().getTime()}`;
    return config;
  },
  (err) => {
    notification.error({
      title: "发送请求失败",
      message: err,
    });
    return false;
  }
);

service.interceptors.response.use(
  (res) => {
    const { status, data, statusText } = res;
    return new Promise((resolve, reject) => {
      if (status < 200 || status > 300) {
        notification.error({
          message: `请求错误: ${status}`,
          description: statusText,
        });
      } else if (!data.success) {
        if (["90001005"].includes(data.code)) {
          return resolve(data || true);
        }
        if (data.code === DATA_CODE.LOGIN_ERROR && window.location.hostname !== "localhost") {
          localStorage.removeItem("token");
          source.cancel("登录失效");
          const { origin } = parseQueryString();
          const _origin = decodeURIComponent(origin)?.split('/module')[0]
          window.location.href = _origin;
          return reject(data);
        }
      } else {
        return resolve(data || true);
      }
      return reject();
    });
  },
  (err) => {
    notification.error({
      title: "提示",
      message: "系统错误",
    });
    return false;
  }
);

export default service;
