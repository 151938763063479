const API_ENV = process.env.REACT_APP_API_ENV;
const RUN_ENV = process.env.REACT_APP_RUN_ENV;

export const ENV = process.env.__fle_env__ || 'dev';

/**
 * 接口 gateway
 * @type {Map<string, string>}
 */
const apiHostMap = new Map([
	['dev', 'https://dev-gateway.fxqifu.net/pangu'],
	['qa', 'https://qa-gateway.fxqifu.net/pangu'],
	['prod', 'https://gateway.fxqifu.net/pangu']
]);

/**
 * 预览地址
 * @type {Map<string, string>}
 */
const previewH5Map = new Map([
	['dev', 'http://dev-preview.fleshopy.com'],
	['qa', 'https://qa-preview.fleshopy.com'],
	['prod', 'https://preview.fleshopy.com']
]);

export const getSiteUrl = (id) => {
	const siteUrlMap = new Map([
		['dev', `http://${id}.dev-shop.fleshopy.com`],
		['qa', `https://${id}.qa-shop.fleshopy.com`],
		// ['qa', `http://localhost:8080`],
		['prod', `https://${id}.shop.fleshopy.com`]
	]);
	return siteUrlMap.get(ENV);
};

export const getyunyingGoodsDetails = (id) => {
	const operateGoodsMap = new Map([
		['dev', `http://dev-yunying.fxqifu.com/goods/detail?id=${id}`],
		['qa', `http://qa-yunying.fxqifu.com/goods/detail?id=${id}`],
		['prod', `https://yunying.fxqifu.com/goods/detail?id=${id}`]
	]);
	return operateGoodsMap.get(ENV);
};

export const getSiteGoodsDetails = (id, editId) => {
	const siteGoodsUrlMap = new Map([
		['dev', `http://dev-site.fleshopy.com/${editId}/commodityDetail/?goodsId=${id}`],
		['qa', `http://qa-site.fleshopy.com/${editId}/commodityDetail/?goodsId=${id}`],
		['prod', `https://site.fleshopy.com/${editId}/commodityDetail/?goodsId=${id}`]
	]);
	return siteGoodsUrlMap.get(ENV);
};

export const getSitePersonageUrl = () => {
	const sitePersonUrlMap = new Map([
		['dev', 'http://dev-site.fleshopy.com'],
		['qa', 'http://qa-site.fleshopy.com'],
		['prod', 'https://site.fleshopy.com']
	]);
	return sitePersonUrlMap.get(ENV);
};

export const getyunyingBase = () => {
	const operateUrlMap = new Map([
		['dev', 'http://dev-yunying.fxqifu.com'],
		['qa', 'http://qa-yunying.fxqifu.com'],
		['prod', 'https://yunying.fxqifu.com']
	]);
	return operateUrlMap.get(ENV);
};

export const getMarketBase = () => {
	const marketUrlMap = new Map([
		['dev', 'http://dev-www.fxliye.com'],
		['qa', 'http://qa-www.fxliye.com'],
		['prod', 'https://fxliye.com']
	]);
	return marketUrlMap.get(ENV);
};
//
export const mainHost = apiHostMap.get(ENV);
const previewH5UrlMap = new Map([
	['local', 'http://localhost:8080'],
	['server', previewH5Map.get(ENV)]
]);
export const previewH5Url = previewH5Map.get(ENV);

export const getPelitenLoginHost = (str = 'account') => {
	let LOGIN_HOST = null;
	if (/^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/.test(str)) {
		LOGIN_HOST = new Map([
			['dev', `http://${str}`],
			['qa', `https://${str}`],
			['prod', `https://${str}`]
		]);
	} else {
		LOGIN_HOST = new Map([
			['dev', `http://dev-${str}.peliten.com`],
			['qa', `https://qa-${str}.peliten.com`],
			['prod', `https://${str}.peliten.com`]
		]);
	}
	return LOGIN_HOST.get(ENV);
};

export const DATA_CODE = {
	SUCCESS: 2000,
	QUERY_PARAMS_ERROR: 3000,
	LOGIN_ERROR: '100003',
	OTHER_ERROR: 5000
};
