import React from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { ProLayout, GridContent } from '@ant-design/pro-components';
import theme from '@fle-ui/plus-theme';

const { proTheme } = theme;

const Layout: React.FC = () => {
  const location = useLocation();

  return (
    <ProLayout
      pure
      token={proTheme}
      defaultCollapsed
      breakpoint={false}
      pageTitleRender={false}
      location={location}
    >
      <GridContent>
        <Outlet />
      </GridContent>
    </ProLayout>
  );
};

export default Layout;