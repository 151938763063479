import React, { FC, ReactElement } from 'react';
import PlusRouter from '@fle-ui/plus-router';
import routerConfig from '@/const/router.config';

const RouterPack: FC = (): ReactElement => {
  // app更换为你想要的名称哦
  return (
    <PlusRouter routes={routerConfig}  />
  )
}

export default RouterPack; 
