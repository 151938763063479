class Message {

  [key: string]: any;
  
  constructor(win: any = null, targetOrigin: any = null) {
    this.win = win;
    this.targetOrigin = targetOrigin;
    this.actions = {};
    window.addEventListener('message', this.handleMessageListener, false);
  }

  handleMessageListener = (event: any) => {
    // 我们能相信信息的发送者吗?  (也许这个发送者和我们最初打开的不是同一个页面).
    if (event.origin !== this.targetOrigin) {
      console.warn(`${event.origin}不对应源${this.targetOrigin}`);
      return;
    }
    if (!event.data || !event.data.type) {
      return;
    }
    const { type } = event.data;
    if (!this.actions[type]) {
      console.warn(`${type}: missing listener`);
      return;
    }
    this.actions[type](event.data.value);
  };

  on = (type: any, cb: any) => {
    this.actions[type] = cb;
    return this;
  };

  emit = (type: any, value: any) => {
    this.win.postMessage({
      type, value,
    }, this.targetOrigin);
    return this;
  };

  destroy() {
    window.removeEventListener('message', this.handleMessageListener);
  }
}

export default Message;